<template>
  <v-card
    dark
    :width="width"
    :height="height"
  >
    <v-carousel 
      v-model="controller.active"
      :show-arrows="false"
      :height="height"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(ad, i) in controller.ads"
        :key="`program-ad-${i}`"
        eager
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <media-player
          :url="ad.media"
          :autoplay="ad.active"
          @finish="next"
        />
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<style lang="scss">

</style>

<script>
  import {
    mdiArrowDown,
    mdiArrowUp,
    mdiCheck,
    mdiPlus
  } from '@mdi/js'

  export default {
    props: {
      ads: {
        type: Array,
        default: () => []
      },
      width: {
        type: [String, Number],
        default: 288
      },
      height: {
        type: [String, Number],
        default: 96
      },
    },
    
    data: () => ({
      icons: {
        add: mdiPlus,
        up: mdiArrowUp,
        down: mdiArrowDown,
        check: mdiCheck
      },
      controller: {
        active: 0,
        ads: [],
        playing: false,
        duration: 8000,
        timer: null
      },
    }),
    
    computed: {
      
      
    },

    watch: {
      ads: {
        immediate: true,
        deep: true,
        handler (ads) {
          console.log('ads', ads);
          this.controller.ads = !!ads ? _.map(_.cloneDeep(ads), ad => {
            return { ...ad, active: false }
          }) : [];
          if (!!ads) this.play(0);
        }
      }
      
    },

    methods: {
      play (index) {
        this.controller.active = index;
        this.controller.ads[index].active = true;
      },
      next () {
        const controller = this.controller;
        const active = controller.active==controller.ads.length-1 ? 0 : controller.active+1;
        controller.active = active;
        _.each(controller.ads, ad => {
          ad.active = false;
        })
        controller.ads[controller.active].active = true;
        console.log(controller.active)
      }
    },

    mounted () {
    },

    components: {
      MediaPlayer: () => import('@/components/MediaPlayer'),
    }
  }
</script>